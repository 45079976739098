<template>
	<div
		class="cart_item flex items-center justify-between p-2 mb-2">
		<div class="cart_item__left flex items-center me-3">
			<div class="cart_item__img me-2 overflow-hidden ratio ratio-1x1">
				<img :src="item.thumbnail" />
			</div>
			
			<div class="cart_item__title">
				<p class="font-bold mb-1">{{item.name}}</p>
				<ShopQuantityField v-if="qtyField" :quantity="item.quantity" @changeQty="changeQuantity" />
			</div>
		</div>
		<div class="cart_item__right flex items-center">
			<span v-if="!qtyField" class="text-end me-2 text-sub text-sm" style="white-space: nowrap;" >
				{{item.quantity}} шт.
			</span>
			<p class="cart_item__price font-bold text-success mb-0 me-2" style="white-space: nowrap;">{{item.price * item.quantity}} ₽</p>
			<button v-if="deleteBtn" @click="removeFromCart(item.id)" class="btn btn-circle text-sub">
				<vue-feather type="trash" />
			</button>
		</div>
	</div>
</template>

<script>
import ShopQuantityField from './ShopQuantityField'
export default {
  components: { ShopQuantityField },
    props: {
        item: {
            type: Object,
            required: true
        },
        deleteBtn: {
            type: Boolean,
            default: true
        },
		qtyField: {
			type: Boolean,
            default: true
		}
    },
	methods: {
		removeFromCart(id){
			this.$store.commit('shop/removeFromCart', id)
		},
        changeQuantity(val){
            this.$store.commit('shop/changeQuantity', {id: this.item.id, val: val})
        }
	}
}
</script>

<style scoped>
.cart_item{
	background: #F5F6FA;
}
.cart_item__img {
	width: 60px;
}

.cart_item__img img{
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}

.cart_item__tags{
	max-width: 220px;
	font-size: 12px;
}

.cart_item__price{
	font-size: 18px;
}

@media(max-width: 768px){
    .cart_item__img {
        width: 30px;
        border-radius: 3px !important;
    }

    .cart_item__price{
        font-size: 14px;
    }
}
</style>
