<template>
	<button @click="cart_is_visible = true" id="btn__shop_cart" class="cart_button content_box flex items-center py-1 px-2 ms-4" :class="className">
		<vue-feather type="shopping-cart" size="17" class="text-primary me-2" />
		<div class="cart_button__meta hidden d-md-block">
			<p class="mb-0 font-bold cart_button__price text-sm text-start">{{CARTSUMM}} ₽</p>
			<p class="mb-0 text-sub cart_button__count">{{countText}}</p>
		</div>
	</button>
        <ShopCart 
            :visible="cart_is_visible"
		    @hide="cart_is_visible = false"
            @onCheckout="$emit('onCheckout')"
            :direct_checkout="direct_checkout"
        />
</template>

<script>
import ShopCart from '../offcanvases/ShopCart'
import {pluralize} from '@/helpers/pluralize'
export default {
    props: {
        className: {
            type: String,
            default: null
        },
        direct_checkout: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            cart_is_visible: false
        }
    },
    computed: {
        CARTSUMM(){
            return this.$store.getters['shop/CARTSUMM'];
        },
        CARTCOUNT(){
            return this.$store.getters['shop/CARTCOUNT'];
        },
        countText(){
            return pluralize(this.CARTCOUNT, ['товар', 'товара', 'товаров'])
        }
    },
    components: {
    ShopCart,
    },
    emits: ['onCheckout']
}
</script>

<style>
.cart_button {
	border: none;
	transition: all 0.2s;
}
.cart_button:hover {
	box-shadow: 0 1px 5px rgb(0 0 0 / 3%);
}
.cart_button__count {
	font-size: 12px;
}
</style>
