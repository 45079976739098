<template>
	<div>
	<OffcanvasInline
		id="pht_cart"
		@hide="$emit('hide'), (visible = !visible)"
		:visible="visible">
		<p class="section_title_medium mb-3">Корзина</p>
		<template v-if="existItems">
		<div  class="cart_items mb-4">
			<ShopCartItem 
				v-for="(item, i) in CART"
				:key="i"
				:item="item"
				:deleteBtn="true" />
		</div>
		
		<div class="cart_summ flex items-center justify-between font-bold py-4">
			<p>Итого:</p>
			<p class="text-success">{{CARTSUMM}} ₽</p>
		</div>

		<button v-if="direct_checkout" class="btn btn-lg btn-primary btn-primary-shadow rounded-0 w-full uppercase py-4" @click.prevent="$emit('onCheckout'), $emit('hide')">Оформить заказ</button>

		<NuxtLink
		v-else
		@click="$emit('hide'), (visible = !visible)"
		:to="{name: 'shop_checkout'}"
		class="btn btn-lg btn-primary btn-primary-shadow rounded-0 w-full uppercase py-4"
		>Оформить заказ</NuxtLink>

		</template>
		<div v-else class="fs-3 py-3 text-sub text-center">
			<img src="/img/table_no_content.svg" class="w-full">
			<div>Корзина пока пуста</div>
    	</div>
		

	</OffcanvasInline>
	</div>
</template>

<script>
import ShopCartItem from '../UI/ShopCartItem.vue'
import OffcanvasInline from './OffcanvasInline.vue'
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		direct_checkout: {
            type: Boolean,
            default: false
        }
	},
	data() {
		return {}
	},
	created(){
		this.subscribeMutations()
		this.setLocalStorageCart()
	},
	computed: {
		CART(){
			return this.$store.getters['shop/CART']
		},
		CARTSUMM(){
            return this.$store.getters['shop/CARTSUMM'];
        },
		existItems(){
			if(!_.isEmpty(this.CART)) return true
			return
		},
	},
	methods: {
		subscribeMutations(){
			this.$store.subscribe(mutation => {
				if (mutation.type.startsWith('shop')){
					if(mutation.type != 'shop/setCart'){
						this.$store.dispatch('shop/changeLocalStorage')
					}
				}
			})
		},
		setLocalStorageCart(){
			this.$store.commit('shop/setCart')
		}
	},
	components: {
		OffcanvasInline,
		ShopCartItem
	},
	emits: ['changeData', 'onCheckout'],
}
</script>

<style scoped>
.cart_summ{
	border-top: 1px solid rgba(255, 255, 255, 0.08);
	font-size: 24px;
}
</style>
